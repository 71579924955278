import React from "react";
import { Seo } from "../components/Helpers/Seo";
import Layout from "../components/Layout/Layout";
import Header from "../components/Header/Header";
import { Container, Row, Col } from "react-bootstrap";
import * as styles from "../styles/pages/cookie-policy.module.scss";
import { graphql } from "gatsby";
import DOMPurify from "isomorphic-dompurify";

export const Head = ({ data }) => (
    <Seo title={data.wpPage.seo.title} description={data.wpPage.seo.metaDesc} keywords={data.wpPage.seo.metaKeywords} />
);

const CookiePolicy = ({ data }) => {
    const cookiePolicy = data.wpPage.acfCookiePolicy.cookiePolicy;

    return (
        <Layout footerBackgroundClass="bg-white">
            <Container fluid>
                <Header />
            </Container>
            <Container className={styles.cookiePolicy}>
                <Row>
                    <Col xs={12} lg={8}>
                        <h1
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(cookiePolicy.heading),
                            }}
                        />
                        <h6 style={{ marginBottom: 30 + "px" }}>
                            Last updated:{" "}
                            <span
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cookiePolicy.lastUpdatedDate) }}
                            />
                        </h6>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(cookiePolicy.bodyText),
                            }}
                        />
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default CookiePolicy;

export const pageQuery = graphql`
    query {
        wpPage(databaseId: { eq: 406 }) {
            seo {
                metaDesc
                title
                metaKeywords
            }
            acfCookiePolicy {
                cookiePolicy {
                    heading
                    lastUpdatedDate
                    bodyText
                }
            }
        }
    }
`;
